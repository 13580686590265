import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/cloudflare';
import { json, redirect } from '@remix-run/cloudflare';
import { useActionData, useLoaderData, useLocation } from '@remix-run/react';
import { BOXSHADOWS, COLORS } from '@tbd/life-tokens';
import type { UserGroupName } from '@tbd/simcapture-api-types';
import invariant from '~/tiny-invariant';

import { getUserStartPath } from '~/auth';
import { createAuthenticatedUser, getRedirectTo } from '~/auth.server';
import { ToolbarOffset } from '~/components/ToolbarOffset';
import { SignIn, type SignInProps } from '~/features/SignIn';
import type { CookieClient } from '~/sessions.server';
import {
  getClusterUserId,
  getHeaderToCreateClientsCookie,
  getHeaderToCreateClusterUserIdCookie,
  getHeaderToCreateUserCookie,
  getHeaderToCreateUserTokenCookie,
  getUserToken,
} from '~/sessions.server';
import { fetchCurrentClient, transformResponse } from '~/simcapture/client';
import type { ClusterClient } from '~/simcapture/clusters';
import {
  fetchClusterClients as _fetchClusterClients,
  getClientPassthroughAuthResponse,
} from '~/simcapture/clusters';
import { fetchUserProfile } from '~/simcapture/fetchUserProfile';
import type { AuthResponseClient } from '~/simcapture/liftAuthenticate';
import { liftAuthenticate } from '~/simcapture/liftAuthenticate';
import { simcaptureAuthenticate } from '~/simcapture/scAuth';
import { getClientHost, getClientOrigin, isLiftUrl } from '../../../domains';
import { clearFormDataFromLocalStorage } from '#app/util/localStorageForms.ts';

type FormErrorKey = 'username' | 'password';
type FormErrors = Record<FormErrorKey, string | undefined>;

type LoginErrorName =
  | 'LOGIN_ERROR'
  | 'LOGIN_INACTIVE'
  | 'LOGIN_LOCKOUT'
  | 'MULTIPLE_USER_MATCHES_ERROR';
type LoginErrorMessages = Record<LoginErrorName, string>;

export const meta: MetaFunction<typeof loader> = () => {
  return [{ title: 'LIFT - Login' }];
};

const loginErrorMessages: LoginErrorMessages = {
  LOGIN_ERROR: 'Invalid login or password',
  LOGIN_INACTIVE:
    'Your account has been deactivated. Please contact an admin if you need to regain access.',
  LOGIN_LOCKOUT:
    'You have entered an incorrect password too many times. Your account has been temporarily locked. Please try again later.',
  MULTIPLE_USER_MATCHES_ERROR:
    'Unable to log in. Duplicate accounts were found with these details, please contact a system administrator for help.',
};

const isLoginErrorName = (maybe: string): maybe is LoginErrorName =>
  maybe in loginErrorMessages;

function getLoginErrorMessage(errorName: string) {
  const errorMessage = isLoginErrorName(errorName)
    ? loginErrorMessages[errorName]
    : `There was an issue logging in: '${errorName}'`;

  return errorMessage;
}

// See ./.login.md for current/desired sequence
export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { clients } = context;
  const url = new URL(request.url);
  const userToken = await getUserToken(request);
  if (!userToken) {
    console.debug('No user token found. Returning to login screen');
    return json({ clients: false, href: url.href });
  }

  if (Array.isArray(clients) && clients.length === 1) {
    console.debug('Single client found');
    return createUserSessionAndRedirectToClient({
      request,
      token: userToken,
    });
  }
  console.debug('Multiple clients found');
  const clusterUserId = await getClusterUserId(request);
  console.debug('clusterUserId:', clusterUserId);
  return json({
    clients: await fetchClusterClients(userToken, clusterUserId),
    clusterUserId,
    href: url.href,
  });
};

// See ./.login.md for current/desired sequence
export const action = async ({ request, context }: ActionFunctionArgs) => {
  let { clients: cookieClients } = context;
  const url = new URL(request.url);
  const formData = await request.formData();
  let authResultClients: AuthResponseClient[] | undefined;
  let userToken = await getUserToken(request);
  let clusterUserId = await getClusterUserId(request);

  try {
    if (!cookieClients) {
      // haven't saved a clients cookie, so assume this is step 1: user/pass auth
      const { username, password, errors } = validateLoginForm(formData);
      if (errors) {
        try {
          console.debug(
            'Login form validation errors:',
            JSON.stringify(errors)
          );
        } catch (error) {
          console.error('Error logging login form validation errors', error);
        }
        return json({ errors });
      }

      const authResult = await liftAuthenticate({ username, password });
      if ('errorCode' in authResult || 'error' in authResult) {
        console.debug('Error logging in:', authResult);
        return json({ error: authResult });
      }
      authResultClients = authResult.clients;
      cookieClients = createCookieClients(authResultClients);

      if (authResultClients.length > 1) {
        const scAuthData = await simcaptureAuthenticate({
          username,
          password,
          clientSubdomain: authResultClients[0].clusterSubDomain,
        });
        userToken = scAuthData.token;
        clusterUserId = scAuthData.userId;
      }
    }

    invariant(
      Array.isArray(cookieClients) && cookieClients.length >= 1,
      'clients is Client[]'
    );

    const clientId = formData.get('clientId')?.toString();
    if (!clientId) {
      return handleNoClientId({
        clusterUserId,
        cookieClients,
        authResultClients,
        userToken,
        url,
      });
    }

    // we have a clientId, so we're logging into a client
    invariant(userToken, 'missing user token');
    invariant(clusterUserId, 'missing clusterUserId');
    const clusterClients = await fetchClusterClients(userToken, clusterUserId);
    const client = clusterClients.find((c) => c.clientId === clientId);
    // if we have a clientId, but no client, something went wrong
    if (!client) {
      console.error(
        `Unable to log in. Cannot find clientId '${clientId}' in cluster clients`
      );
      return json({
        error: {
          errorName: 'LOGIN_ERROR',
        },
      });
    }

    // we have a client & clientId, so get the passthrough auth token
    return handleHasClientId({
      clientId: client.clientId,
      userToken,
      request,
    });
  } catch (error) {
    console.error(error);
    return json({
      error: {
        errorName: error instanceof Error ? error.message : 'UNKNOWN_ERROR',
      },
    });
  }
};

function createCookieClients(clientsIn: AuthResponseClient[]) {
  const uniqueSubdomains = [
    ...new Set(clientsIn.map((client) => client.clusterSubDomain)),
  ];
  const clientsOut: CookieClient[] = uniqueSubdomains.map((cluster) => ({
    clusterSubDomain: cluster,
  }));

  // we only need to know if there is 1 client or 2+ clients,
  // if there's only one unique 'clusterSubDomain', duplicate it
  // this make sure it's treated as a multi-client account
  if (clientsIn.length > 1 && clientsOut.length === 1) {
    clientsOut.push(clientsOut[0]);
  }

  return clientsOut;
}

async function createUserSessionAndRedirectToClient(props: {
  request: Request;
  token: string;
}) {
  const { request, token } = props;

  const clientResponse = await fetchCurrentClient(token);
  const userClient = transformResponse(clientResponse);
  const profileResult = await fetchUserProfile({ token });
  if ('errorCode' in profileResult) {
    if (
      profileResult['errorCode'].startsWith('401') ||
      profileResult['errorCode'].startsWith('403')
    ) {
      const message = `'${profileResult['errorCode']}' error response`;
      const fullMessage = `${message} ${profileResult}`;
      throw redirect('/logout', { headers: { 'x-debug': fullMessage } });
    }
    return json({ error: profileResult });
  }

  const user = createAuthenticatedUser({
    client: userClient,
    token,
    profileResult,
  });
  console.debug(
    `Created authenticated user: ${user.userName} for ${userClient.clientName}`
  );
  const redirectTo = getRedirectTo(request) || getUserStartPath(user);
  const subdomain =
    userClient.clientSubDomain?.toString() || 'MISSING_SUBDOMAIN';
  const clientOrigin = getClientOrigin({
    subdomain,
    href: request.url,
  });

  const clientUrl = new URL(redirectTo, clientOrigin);
  const url = new URL(request.url);
  const clientHost = getClientHost({
    subdomain,
    href: url.href,
  });
  const userHeader = await getHeaderToCreateUserCookie({
    request,
    user,
    domain: isLiftUrl(url) ? clientHost : undefined,
  });

  const headers = new Headers({ 'Set-Cookie': userHeader });
  console.debug(`Redirecting to ${clientUrl} and setting user cookie header`);
  throw redirect(clientUrl.toString(), { headers });
}

export default function LoginScreen() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const location = useLocation();

  const params = Object.fromEntries(new URLSearchParams(location.search));
  const actionUrl = `${location.pathname}${location.search}`;
  const signInProps: SignInProps = {
    action: actionUrl,
    redirectTo: params.redirectTo,
  };

  deleteCachedEntries();

  if (
    'clients' in loaderData &&
    Array.isArray(loaderData['clients']) &&
    loaderData['clients'].length > 1
  ) {
    return <ClientSelect clients={loaderData.clients} href={loaderData.href} />;
  }

  if (actionData) {
    if (
      'clients' in actionData &&
      Array.isArray(actionData['clients']) &&
      actionData['clients'].length > 1
    ) {
      return (
        <ClientSelect clients={actionData.clients} href={actionData.href} />
      );
    }

    if ('errors' in actionData) {
      // Avoid TS error & ensure it's Record<string, string> | undefined
      signInProps.errors = Object.create(actionData.errors);
    } else if ('error' in actionData) {
      const errorName =
        'errorName' in actionData.error
          ? actionData.error.errorName
          : 'message' in actionData.error
            ? actionData.error.message
            : 'Unknown error';
      signInProps.errors = {
        login: getLoginErrorMessage(errorName),
      };
    }
  }

  return <SignIn {...signInProps} />;
}

// TODO: update to extract multi client type ('clients', 'username', 'password') from ActionData union type
type ClientSelectProps = {
  clients: ClusterClientForUI[];
  href: string;
};

const userGroupTypeRefToUserGroupName: Record<
  AuthResponseClient['userGroupTypeRef'],
  UserGroupName
> = {
  SystemAdmin: 'System Admin',
  Administrator: 'Administrator',
  Participant: 'Participant',
};

const ClientSelect = ({ clients, href }: ClientSelectProps) => {
  const theme = useTheme();
  return (
    <Container component="main" sx={{ height: 'calc(100vh - 64px)' }}>
      <Typography
        variant="header-xs"
        component="h1"
        sx={{ my: theme.spacing(3) }}
      >
        My Groups
      </Typography>

      {clients.map((client) => {
        const clientOrigin = isLiftUrl(new URL(href))
          ? getClientOrigin({
              subdomain: client.clientSubDomain,
              href,
            })
          : '';
        const action = `${clientOrigin}/login`;
        const userGroupTypeRef = client.isSystemAdmin
          ? 'SystemAdmin'
          : 'Administrator';
        const userGroupName = userGroupTypeRefToUserGroupName[userGroupTypeRef];
        return (
          <form key={client.clientId} method="POST" action={action}>
            <Button
              type="submit"
              name="clientId"
              value={client.clientId}
              sx={{
                p: theme.spacing(2),
                mb: theme.spacing(3),
                background: COLORS.white,
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                alignItems: 'flex-start',
                cursor: 'pointer',
                boxShadow: BOXSHADOWS.BOXSHADOW_L1,
                borderRadius: theme.spacing(1),
              }}
            >
              <Typography
                variant="component-bold-xl"
                sx={{ mb: theme.spacing(2), p: 0 }}
                component="p"
              >
                {client.institutionName}
              </Typography>
              <Box
                sx={{
                  p: theme.spacing(0.5, 1),
                  background: COLORS.neutral_100,
                  display: 'inline-block',
                  borderRadius: theme.spacing(0.25),
                }}
              >
                <Typography variant="component-bold-xs">
                  {userGroupName}
                </Typography>
              </Box>
            </Button>
          </form>
        );
      })}
      <ToolbarOffset />
    </Container>
  );
};

function validateLoginForm(formData: FormData):
  | {
      username: string;
      password: string;
      errors: null;
    }
  | {
      username?: string;
      password?: string;
      errors: FormErrors;
    } {
  const username = formData.get('username')?.toString();
  const password = formData.get('password')?.toString();
  if (username && password) {
    return { username, password, errors: null };
  }
  const errors: FormErrors = {
    username: undefined,
    password: undefined,
  };
  if (!username) errors.username = 'Username is required';
  if (!password) errors.password = 'Password is required';

  return { username, password, errors };
}

async function handleSingleClient(props: {
  client: AuthResponseClient;
  url: URL;
  clientsCookieHeader: string;
}) {
  const { client, url, clientsCookieHeader } = props;
  const clientHost = getClientHost({
    subdomain: client.clientSubDomain,
    href: url.href,
  });
  console.debug(`Single client found: ${client.clientId}`);
  const userTokenHeader = await getHeaderToCreateUserTokenCookie({
    token: client.token,
    url,
  });
  const singleClientCookieHeaders = new Headers([
    ['Set-Cookie', clientsCookieHeader],
    ['Set-Cookie', userTokenHeader],
  ]);
  console.debug(
    `Redirecting to ${clientHost} and setting single client cookie headers`
  );
  return redirect(`//${clientHost}/login`, {
    headers: singleClientCookieHeaders,
  });
}

async function handleMultipleClients(props: {
  userToken: string;
  clientsCookieHeader: string;
  url: URL;
  clusterUserId: string;
}) {
  const { userToken, clientsCookieHeader, clusterUserId, url } = props;
  const [userTokenHeader, clusterClients, cluserUserIdHeader] =
    await Promise.all([
      getHeaderToCreateUserTokenCookie({
        token: userToken,
        url,
      }),
      fetchClusterClients(userToken, clusterUserId),
      getHeaderToCreateClusterUserIdCookie({ clusterUserId, url }),
    ]);
  const multiClientHeaders = new Headers([
    ['Set-Cookie', clientsCookieHeader],
    ['Set-Cookie', userTokenHeader],
    ['Set-Cookie', cluserUserIdHeader],
  ]);

  console.debug('Returning cluster clients and setting multi-client cookies');
  return json(
    { clients: clusterClients, href: url.href },
    { headers: multiClientHeaders }
  );
}

async function handleNoClientId(props: {
  clusterUserId: string | null;
  cookieClients: CookieClient[];
  authResultClients: AuthResponseClient[] | undefined;
  userToken: string | null;
  url: URL;
}) {
  const { clusterUserId, cookieClients, authResultClients, userToken, url } =
    props;
  // have clients, but no clientId
  const clientsCookieHeader = await getHeaderToCreateClientsCookie({
    clients: cookieClients,
    url,
  });

  // if we have a single client, redirect to that client
  if (authResultClients?.length === 1) {
    return handleSingleClient({
      client: authResultClients[0],
      url,
      clientsCookieHeader,
    });
  }

  // we have multiple clients, so show the client select screen
  invariant(userToken, 'missing user token');
  invariant(clusterUserId, 'missing cluserUserId');
  return handleMultipleClients({
    userToken,
    clientsCookieHeader,
    url,
    clusterUserId,
  });
}

async function handleHasClientId(props: {
  clientId: string;
  userToken: string;
  request: Request;
}) {
  const { clientId, userToken, request } = props;
  console.debug(`getting passthrough auth for client ${clientId}`);
  const passthroughAuth = await getClientPassthroughAuthResponse({
    clientId,
    token: userToken,
    request,
  });
  console.debug(`got passthrough auth for client ${clientId}`);
  return createUserSessionAndRedirectToClient({
    request,
    token: passthroughAuth.token,
  });
}

type ClusterClientForUI = Omit<ClusterClient, 'systemAdmins'> & {
  isSystemAdmin: boolean;
};

async function fetchClusterClients(
  token: string,
  clusterUserId: string | null
) {
  const clusterClients = await _fetchClusterClients(token);
  const clients = clusterClients.map((c) => {
    // remove the ClusterClient['systemAdmins'] property
    const { systemAdmins, ...client } = c;
    const clientForUI: ClusterClientForUI = {
      ...client,
      // add isSystemAdmin property
      isSystemAdmin: systemAdmins.some((sa) => sa.userId === clusterUserId),
    };
    return clientForUI;
  });

  try {
    console.debug(
      `Clients for cluster user id ${clusterUserId}: ${JSON.stringify(clients)}`
    );
  } catch (error) {
    console.error('Error logging cluster clients', error);
  }

  return clients;
}

// delete sessionStorage entries from LIFT-696
// https://github.com/blinemedical/mono-lift/pull/646
function deleteCachedEntries() {
  try {
    const cacheKeys = Object.keys(sessionStorage);
    for (const cacheKey of cacheKeys) {
      if (cacheKey.startsWith('l-c-s-c-')) {
        sessionStorage.removeItem(cacheKey);
      }
    }
  } catch (error) {
    // don't log ReferenceErrors
    if (error instanceof ReferenceError !== true) {
      console.error(error);
    }
  }

  clearFormDataFromLocalStorage();
}
