export const LIFT_DEV_DOMAIN = 'laerdal-lift-dev.com';
export const LIFT_UAT_DOMAIN = 'laerdal-lift-uat.com';
export const LIFT_PROD_DOMAIN = 'laerdal-lift.com';
export const LIFT_EXTRA_DOMAIN = 'laerdal-lift-momentum.com';
export const LIFT_DOMAINS = [
  LIFT_DEV_DOMAIN,
  LIFT_UAT_DOMAIN,
  LIFT_PROD_DOMAIN,
  LIFT_EXTRA_DOMAIN,
];

export function isLiftUrl(url: { hostname: string }) {
  return LIFT_DOMAINS.some(
    (domain) => url.hostname === domain || url.hostname.endsWith(`.${domain}`)
  );
}

export function isLaerdalWorker(url: { hostname: string }) {
  return url.hostname.endsWith('.laerdal-labs.workers.dev');
}

type HttpHostname = string;
type ClientSubdomin = string;

const clientSubdomainExceptions: Record<HttpHostname, ClientSubdomin> = {
  [`internal.${LIFT_PROD_DOMAIN}`]: 'labs-lift-europe',
};

export function getClientSubdomain({ href }: { href: string }) {
  const url = new URL(href);

  // if there are exceptions, use them & return early
  if (url.hostname in clientSubdomainExceptions) {
    return clientSubdomainExceptions[url.hostname];
  }

  const urlParts = url.hostname.split('.');
  // only try to guess client from subdomain on the laerdal-lift domains
  // (can't be used on lift-edge-*.laerdal-labs.workers.dev or localhost, 127.0.0.1, etc)
  // only support simple CLIENT.DOMAIN.TLD pattern
  if (isLiftUrl(url) && urlParts.length === 3) {
    const [subdomain] = urlParts;
    return subdomain;
  }
}

type GetClient = { subdomain?: string; href: string };

export function getClientHost({ subdomain, href }: GetClient) {
  const rootHost = getRootHost({ href });

  return isLiftUrl({ hostname: rootHost })
    ? `${subdomain}.${rootHost}`
    : new URL(href).host;
}

export function getRootHost({ href }: { href: string }) {
  const { host, hostname } = new URL(href);
  if (isLiftUrl({ hostname })) {
    const [tld, domain] = hostname.split('.').reverse();
    return `${domain}.${tld}`;
  }
  return host;
}

export function getClientOrigin({ subdomain, href }: GetClient) {
  const host = getClientHost({ subdomain, href });
  const protocol = new URL(href).protocol;
  return `${protocol}//${host}`;
}
